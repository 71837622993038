import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container  from 'react-bootstrap/Container';
import logo from '../../images/logo/Logo_Performance.webp';
import { Imprint, Icon, ImprintH1, ImprintDiv } from './ImprintElements';

const ImprintSection = () => {
  return (
    <Imprint>
      <Icon to="/" > <img src={logo} width={50} height='auto' alt="Performance Room Logo"></img></Icon>
       <Container>
         <ImprintH1> Impressum </ImprintH1>
         <Col className='lg-8 d-flex flex-column align-items-stretch'>
            <ImprintDiv className='content ps-lg-4 d-flex flex-column justify-content-center'>
                <Row>
                    <Col className='lg-12 py-5'>
                      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                      <p>Edward Kankam<br />
                      Kornbergstra&szlig;e 26<br />
                      70176 Stuttgart</p>

                      <h2>Kontakt</h2>
                      <p>Telefon: +49 157 390 69 762<br />
                      E-Mail: training@pr-ek.de</p>

                      <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                      <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                      <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>

                      <p>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV: Edward Kankam</p>
                    </Col>
                </Row>
            </ImprintDiv>           
         </Col>
       </Container>
    </ Imprint>
  )
}

export default ImprintSection;
