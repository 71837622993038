import React from 'react';
import { BiDumbbell, BiTimeFive} from 'react-icons/bi';
import {BsBatteryCharging} from 'react-icons/bs';
import {FaWeight} from 'react-icons/fa';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import {BenefitsContainer, BenefitsH1, BenefitsWrapper, BenefitsCard, IconBox, Icon, BenefitsH3, BenefitsP} from './BenefitsElements'

const Benefits = () => {

    return(
        <BenefitsContainer id='benefits'>
            <BenefitsWrapper>
            <BenefitsH1> Vorteile mit PR </BenefitsH1>
                <BenefitsCard>
                    <AnimationOnScroll delay={70} animateIn="animate__fadeIn">
                        <IconBox>
                            <Icon><BiDumbbell/></Icon>
                            <BenefitsH3>Krafttraining</BenefitsH3>
                            <BenefitsP>Spür- sowie Sichtbare Leistungssteigerung durch strukturiertes Krafttraining.</BenefitsP>
                        </IconBox>
                    </AnimationOnScroll>
                    <AnimationOnScroll delay={140} animateIn="animate__fadeIn">
                    <IconBox>
                        <Icon><BsBatteryCharging /></Icon>
                        <BenefitsH3>Mehr Energie</BenefitsH3>
                        <BenefitsP>Performance Steigerung, mehr Energie durch Ernährungdesign, Lifestyle und Schlafoptimierung.</BenefitsP>
                    </IconBox>
                    </AnimationOnScroll>
                    <AnimationOnScroll delay={210} animateIn="animate__fadeIn">
                    <IconBox>
                        <Icon><FaWeight/></Icon>
                        <BenefitsH3>Gewichtsreduktion</BenefitsH3>
                        <BenefitsP>Schnelle Körperfettverbrennung durch effektive Strategien und guter Planung.</BenefitsP>
                    </IconBox>
                    </AnimationOnScroll>
                    <AnimationOnScroll delay={280} animateIn="animate__fadeIn">
                    <IconBox>
                        <Icon><BiTimeFive/></Icon>
                        <BenefitsH3>Zeitersparnis</BenefitsH3>
                        <BenefitsP>Zeit sparen durch mehr Fokus - individuelle Betreuung.</BenefitsP>
                    </IconBox>
                    </AnimationOnScroll>
                </BenefitsCard>
            </BenefitsWrapper>
        </BenefitsContainer>
    )
}

export default Benefits;