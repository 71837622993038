export const homeObjOne = {
    id: 'about',
    topLine: 'Das bin ich',
    headline: 'Hallo, willkommen ich bin Edward, der Trainer.',
    description1: 'Du möchtest erfolgreich und dauerhaft Abnehmen oder deine Performance im Beruf und im Alltag steigern? Dann bist du hier richtig! Ich helfe dir dabei in kurzer Zeit, gesund und nachhaltig einen schlanken, starken und vitalen Körper zu erlangen.',
    description2: 'Mit einem strukturierten Krafttraining und mit einem für dich alltagstauglichen Ernährungsprogramm steigern wir deine Performance und das Langfristig.',
    buttonLabel: 'Zum Kontakt',
    imgStart: false,
    img: require('../../images/training-2-min.webp'),
    alt: 'Personal Training'
}

export const resumeObj = {
    text1: "Hi, ich bin Coach Eddy, 1986 geboren und aufgewachsen im schönen Stuttgart. Schon immer ist Sport meine Leidenschaft.Mit Fußball hat die Liebe und meine Faszination für Sport angefangen, nach ein paar Jahren ist mir jedoch bewusst geworden, dass Fußball mich nicht so sehr fordert, wie American Football das kann. Hier habe ich in der 1. und 2. Bundesliga gespielt. Danach hat es mich zum Kampfsport gezogen (Brazilian Jiu-Jutsu & Thaiboxen). Das Krafttraining hat mich in all dieser Zeit bis heute begleitet. Ich war immer auf der Suche das eigene Krafttraining zu optimieren, mit dem Willen meine Performance zu Steigerung und so Sport auf einem hohen Leistungsniveau auszuüben."
}