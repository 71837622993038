import React from 'react';
import {FaRegCircle} from 'react-icons/fa';
import Accordion from 'react-bootstrap/Accordion';
import Container  from 'react-bootstrap/Container';
import logo from '../../images/logo/Logo_Performance_WText.png';
import {FaChevronLeft} from 'react-icons/fa'; 
import { Service, NavBtn, NavBtnLink, Icon, Services, ServiceH1, ServiceH2, ServiceUl, ServiceLi, ServiceB, ServiceLittle} from './ServiceElements';

const ServiceSection = () => {
  return (
    <Service>
        <NavBtn>
          <Icon to="/" > <img src={logo} width={90} height='auto' alt="Performance Room Logo"></img></Icon>
          <NavBtnLink to="/" smooth={true} duration={500} spy={true} exact='true' offset={-80}><FaChevronLeft/> Zurück</NavBtnLink> 
        </NavBtn>
            <Container>
              <ServiceH1> Trainingsangebot</ServiceH1>
              <Services>
                <Accordion flush>
                    <Accordion.Item eventKey="0" style={{ margin: '15px' }}>
                      <Accordion.Header style={{ background: 'var(--main-color-light)', border: ' 1.5px solid var(--main-color-red)'}}><ServiceH2>Personal Training</ServiceH2></Accordion.Header>
                      <Accordion.Body style={{  border: ' 1.5px solid var(--main-color-red)'}}>
                      <ServiceUl>
                          <ServiceLi>Einzeltraining oder mit dem Partner möglich</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Personal Training</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Trainingszeiten können variabel festgelegt werden</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Individueller Trainingsplan der alle 4 Wochen geändert wird</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> 2 Körperfettmessungen sowie Ernährungs- und Supplementplan, welcher anhand der Messergebnisse erstellt wird</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Wasser und post workout Shake während und nach dem Training</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> 12 Wochen Paket</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Ganze Wochen können auf Grund von Krankheit oder Urlaub pausiert werden.</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Keine Vertragliche Bindung</ServiceLi>
                          <br></br>
                          <ServiceLi><ServiceB>Ort: </ServiceB>Performance Room - Stuttgart</ServiceLi>
                          <ServiceLi><ServiceB>Zeit: </ServiceB>Flexible Termine</ServiceLi>
                          <ServiceLi><ServiceB>Mindestlaufzeit: </ServiceB>Flexibel</ServiceLi>
                          <ServiceLi><ServiceB>Preis: </ServiceB>85€/Std.</ServiceLi>
                      </ServiceUl>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" style={{ margin: '15px' }}>
                      <Accordion.Header style={{ background: 'var(--main-color-light)', border: ' 1.5px solid var(--main-color-red)'}}><ServiceH2>Bootcamp</ServiceH2></Accordion.Header>
                      <Accordion.Body style={{  border: ' 1.5px solid var(--main-color-red)'}}>
                      <ServiceUl>
                          <ServiceLi>Outdoortraining in einer kleinen Gruppe</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> 60 min Zirkel-/ Modifiziertes Strongmantraining</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Kleine Gruppen für optimale Betreuung</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Trainingsplan wird alle 4 Wochen geändert</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Keine Vertragliche Bindung</ServiceLi>
                          <br></br>
                          <ServiceLi><ServiceB>Ort: </ServiceB>Outdoor in Stuttgart</ServiceLi>
                          <ServiceLi><ServiceB>Zeit: </ServiceB>Samstags 8:30 Uhr - 14 Uhr</ServiceLi>
                          <ServiceLi><ServiceB>Preis: </ServiceB>80€ a 5 Einheiten</ServiceLi>
                          <ServiceLi><ServiceLittle>Ab dem zweiten Mal nur noch 70€</ServiceLittle></ServiceLi>

                      </ServiceUl>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" style={{ margin: '15px' }}>
                      <Accordion.Header style={{ background: 'var(--main-color-light) ', border: ' 1.5px solid var(--main-color-red)'}}><ServiceH2>Online Coaching</ServiceH2></Accordion.Header>
                      <Accordion.Body style={{  border: ' 1.5px solid var(--main-color-red)'}}>
                      <ServiceUl>
                          <ServiceLi>Alle 3-4 Wochen Online Workout via Zoom/Skype</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Individueller Trainingsplan</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Eigen gewähltes Studio</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Alle 4 Wochen wird ein neuer Trainingsplan erstellt</ServiceLi>
                          <ServiceLi><FaRegCircle size={5} /> Keine Vertragliche Bindung</ServiceLi>
                          <br></br>
                          <ServiceLi><ServiceB>Ort: </ServiceB>Studio deiner Wahl</ServiceLi>
                          <ServiceLi><ServiceB>Zeit: </ServiceB>einmal alle 3-4 Wochen einen 60 min Termin</ServiceLi>
                          <ServiceLi><ServiceB>Mindestlaufzeit: </ServiceB>3 Monate</ServiceLi>
                          <ServiceLi><ServiceB>Preis: </ServiceB>65€/Monat</ServiceLi>
                      </ServiceUl>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>                    
                </Services>
            </Container>
    </ Service>
  )
}

export default ServiceSection;
