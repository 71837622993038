import styled from "styled-components";
import { ExternalLink } from 'react-external-link';

export const Contact = styled.section`
background-color: var(--main-color-light);
padding: 35px 0 25px 0;
`;

export const InfoBox = styled.div`
display: flex;
flex-direction: column;
padding: 15px;
border-top: 3px solid var(--main-color-red);
background-color: var(--main-color-light);
text-align: center;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
margin: 10px;
`;

export const ContactIcon = styled.i`
font-size: 20px;
padding-right: 15px;
`;

export const ContactH3 = styled.h3`
font-size: 20px;
color: var(--main-color-dark);
font-weight: 700;
padding-bottom: 15px;
`;

export const ContactP = styled.p`
margin: 0;
`;

export const SocialIcons = styled.div`
display: flex;
justify-content: center;
`;

export const SocialLink = styled(ExternalLink)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
color: var(--main-color-dark);
padding: 15px;
line-height: 24px;
font-size: 1.3em;
margin: 0 5px 0 5px ;
text-decoration: none; 

&:hover{
    color: var(--main-color-red);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
`;

export const ContactH1 = styled.h1`
text-align: center;
font-size: 2.5rem;
color: var(--main-color-dark);
padding: 25px;
margin-bottom: 25px;
line-height: 1.1;
font-weight: 600;

:before {
  position: absolute;
  bottom: 0;
  width: 60px;
  height: 3px;
  content: "";
  background-color: var(--main-color-red);
  left:50%; 
  margin-left:-30px;
}

@media screen and (max-width: 480px){
    font-size: 2rem;
}
`;

export const EmailForm = styled.div`
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 19px; 
background-color: var(--secondary-color-light);
margin: 10px;
`;