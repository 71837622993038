import React from "react";
// import testamonial1 from '../../images/testimonial-1.jpg';
// import testamonial2 from '../../images/testimonial-2.jpg';
// import testamonial3 from '../../images/logo/Logo_Performance.webp';
import {RiDoubleQuotesR, RiDoubleQuotesL} from 'react-icons/ri'
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

import { TestimonialH1, TestimonialH3, TestimonialH4, TestimonialItem, TestimonialP, Testimonials} from "./TestimonialsElements";

const TestimonialsSection = () => {

    return(
    <Testimonials id="testimonials">
      <TestimonialH1>Rezensionen</TestimonialH1>
    <Carousel >
      <Carousel.Item>
        <TestimonialItem>
            {/* <TestimonialsImgBg src={testamonial1} alt="First Testamonial"></TestimonialsImgBg> */}
        <TestimonialH3>Laurent Etemi</TestimonialH3>
          <TestimonialH4>Deutscher Vize-Meister Karate</TestimonialH4>
          <TestimonialP><RiDoubleQuotesR /> Perfekte Stütze für meine Vorbereitungen auf wichtige Wettkämpfe! <RiDoubleQuotesL /></TestimonialP>
        </TestimonialItem>
      </Carousel.Item>
      <Carousel.Item>
      <TestimonialItem>
      {/* <TestimonialsImgBg src={testamonial2}  alt="Second Testamonial"></TestimonialsImgBg> */}
          <TestimonialH3>Jeronim Etemi</TestimonialH3>
          <TestimonialH4>Deutscher Meister Karate</TestimonialH4>
          <TestimonialP><RiDoubleQuotesR /> Perfekte Beratung und klasse Trainer! <RiDoubleQuotesL /></TestimonialP>
        </TestimonialItem>
      </Carousel.Item>
      <Carousel.Item>
      <TestimonialItem>
      {/* <TestimonialsImgBg src={testamonial3} alt="Third Testamonial"></TestimonialsImgBg> */}
        <TestimonialH3>Deborah Delice</TestimonialH3>
          <TestimonialP><RiDoubleQuotesR /> Dieses Training hat mehr als 5 Sterne verdient! <RiDoubleQuotesL /></TestimonialP>
        </TestimonialItem>
      </Carousel.Item>
      <Carousel.Item>
      <TestimonialItem>
      {/* <TestimonialsImgBg src={testamonial3} alt="Fourth Testamonial"></TestimonialsImgBg> */}
        <TestimonialH3>Angelika Kukshaus</TestimonialH3>
          <TestimonialP><RiDoubleQuotesR /> Eddy ist ein motivierter und äußerst sympathischer Personal Trainer, der individuell auf seine Clienten eingeht und dabei hilft das eigene Ziel zu erreichen. <RiDoubleQuotesL /></TestimonialP>
        </TestimonialItem>
      </Carousel.Item>
      <Carousel.Item>
      <TestimonialItem>
      {/* <TestimonialsImgBg src={testamonial3} alt="Fifth Testamonial"></TestimonialsImgBg> */}
        <TestimonialH3>Michaela Stoiber</TestimonialH3>
          <TestimonialP><RiDoubleQuotesR /> Super professionelle Betreuung, individueller Trainings- und Ernährungsplan & beste Motivation! <RiDoubleQuotesL /></TestimonialP>
        </TestimonialItem>
      </Carousel.Item>
    </Carousel>
    </Testimonials> 
    )
}

export default TestimonialsSection;