import React from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SideBtnWrap, SidebarLink, SidebarRoute, SidebarMenu, SidebarExtRoute } from './MobileNavbarElements';

const MobileNavbar = ({isOpen, toggle}) => {
    return (
       <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="about" onClick={toggle}>
                    Home
                </SidebarLink>
                <SidebarLink to="benefits" onClick={toggle}>
                    Vorteile
                </SidebarLink>
                <SidebarExtRoute to="/resume" onClick={toggle}>
                    Resume
                </SidebarExtRoute>
                <SidebarLink to="contact" onClick={toggle}>
                    Kontakt
                </SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute to="/services">Angebote</SidebarRoute>
            </SideBtnWrap>
        </SidebarWrapper>
       </SidebarContainer> 
    )
};

export default MobileNavbar;
