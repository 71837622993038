import styled from "styled-components";
import img from '../../images/testimonials-bg.jpg';

export const Testimonials = styled.section`
/* background: linear-gradient(rgba(0, 0, 0, 0.609), rgba(0, 0, 0, 0.534));  */
/* background: linear-gradient(rgba(0, 0, 0, 0.609), rgba(0, 0, 0, 0.534)), url(${img}) center center no-repeat;  */
background: linear-gradient(rgba(0, 0, 0, 0.609), rgba(0, 0, 0, 0.534)), url(${img}) center center no-repeat;
background-size: cover;
position: relative;

::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(14, 16, 17, 0.7);
  } 
`;

export const TestimonialH1 = styled.h1`
text-align: center;
font-size: 2.5rem;
color: var(--main-color-light);
padding: 25px;
line-height: 1.1;
font-weight: 600;

:before {
  position: absolute;
  bottom: 0;
  width: 60px;
  height: 3px;
  content: "";
  background-color: var(--main-color-red);
  left:50%; 
  margin-left:-30px;
}

@media screen and (max-width: 480px){
    font-size: 2rem;
}
`;

export const TestimonialsImgBg = styled.img`
width: 100px;
/* border-radius: 50%; */
margin: 0 auto;
`;

export const TestimonialItem = styled.div`
text-align: center;
padding: 170px;
color: #fff;
@media screen and (max-width: 700px){
    padding: 20px;
}
`;

export const TestimonialH3 = styled.h3`
font-size: 20px;
font-weight: bold;
margin: 10px 0 5px 0;
color: #fff;
`;

export const TestimonialH4 = styled.h4`
font-size: 14px;
color: #ddd;
margin: 0 0 15px 0;
`;

export const TestimonialP = styled.p`
font-style: italic;
margin: 0 auto 15px auto;
color: #eee;
`;