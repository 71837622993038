import React from 'react';
import Img from '../../images/edward-kankam-min.webp'
import Logo from '../../images/logo/Logo_Performance_WText.png';
import { HeroContainer, HeroBg, ImgBg, HeroContent, HeroImg, HeroP, HeroBtnWrapper, BeginIconArrow, ArrowDown, FadeInDiv } from './HeroSectionElements';

const HeroSection = () => {
  return (
    <HeroContainer id="home">
        <HeroBg>
            <ImgBg src={Img}/>
        </HeroBg>
        <HeroContent>
                <HeroImg src={Logo} ></HeroImg>
            <FadeInDiv>
            <HeroP> PERSONAL TRAINING IN STUTTGART</HeroP>
            </FadeInDiv>
            <HeroBtnWrapper>
            <FadeInDiv>
            <BeginIconArrow to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                <ArrowDown />
            </BeginIconArrow>
            </FadeInDiv>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection;
