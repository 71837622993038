import styled from "styled-components";
import {Link} from 'react-router-dom';

export const Service = styled.section`
left: 0;
top: 0;
right: 0;
bottom: 0;
padding: 15px;
background: #ffffff;
`;

export const Icon = styled(Link)`
display: flex;
padding: 15px;
justify-self: flex-start;
cursor: pointer;
align-items: center;
`;

export const ServiceH1 = styled.h1`
text-align: center;
font-size: 2.5rem;
color: var(--main-color-dark);
padding: 25px;
margin-bottom: 24px;
line-height: 1.1;
font-weight: 600;

:before {
  position: absolute;
  bottom: 0;
  width: 60px;
  height: 3px;
  content: "";
  background-color: var(--main-color-red);
  left:50%; 
  margin-left:-30px;
}

@media screen and (max-width: 480px){
    font-size: 2rem;
}
`;

export const Services = styled.div`
padding: 100px;

@media screen and (max-width: 780px){
 padding: 10px;   
}
`;

export const ServiceH2 = styled.h2`
font-size: 1.3rem;
padding: 15px;
`;

export const ServiceHeader = styled.div`
background: var(--main-color-red);
`;

export const ServiceUl = styled.ul`
padding: 0;
list-style: none;
color: var(--main-color-dark);
line-height: 20px;
font-size: 16px;
`;

export const ServiceLi = styled.li`
padding-bottom: 16px;
font-weight: 300;
`;

export const ServiceB = styled.b`
`;
export const ServiceLittle = styled.sub`
`;
export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    position: sticky;
    top: 0;
    z-index:99;
    background: var(--main-color-light);
    `;

    export const NavBtnLink = styled(Link)`
    background: transparent;
    white-space: nowrap;
    padding: 10px 22px;
    color: var(--main-color-dark);
    border: 2.5px solid var(--main-color-red);
    font-size: 16px;
    font-weight: 300;
    outline: none;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        transition: all 0.3s ease-in-out;
        background: var(--main-color-red);
        color: var(--main-color-light);
    }
    `;