import styled from "styled-components";
import {FaTimes} from	'react-icons/fa';
import {Link as LinkS} from 'react-scroll';
import {Link as LinkR} from 'react-router-dom';

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: var(--main-color-dark);
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3 ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    top: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`;

export const CloseIcon = styled(FaTimes)`
    color: var(--main-color-light);
    
`;

export const Icon = styled.div`
position: absolute;
top: 1.2rem;
right: 1.5rem;
background: transparent;
font-size: 2rem;
cursor: pointer;
outline: none;
`;

export const SidebarWrapper =styled.div`
color: var(--main-color-light);
`;

export const SidebarMenu = styled.ul`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(6, 80px);
text-align: center;
margin-left: -2rem;

@media screen and (max-width: 480px) {
    grid-template-rows:repeat(6, 60px);
}
`

export const SidebarLink = styled(LinkS)`
display: flex;
align-items: center;
justify-content: center;
font-size: 1.7rem;
text-decoration: none;
list-style: none;
transition: 0.2s ease-in-out;
text-decoration: none;
color: var(--main-color-light);
cursor: pointer;

&:hover {
    color: var(--main-color-red);
    transition: 0.2s ease-in-out;
}
`;

export const SideBtnWrap = styled.nav`
display: flex;
justify-content: center;
`;

export const SidebarExtRoute = styled(LinkR)`
display: flex;
align-items: center;
justify-content: center;
font-size: 1.7rem;
text-decoration: none;
list-style: none;
transition: 0.2s ease-in-out;
text-decoration: none;
color: var(--main-color-light);
cursor: pointer;

&:hover {
    color: var(--main-color-red);
    transition: 0.2s ease-in-out;
}
`;

export const SidebarRoute = styled(LinkR)`
background: var(--main-color-red);
white-space: nowrap;
padding: 16px 64px;
color: var(--main-color-light);
font-size: 1.7rem;
font-weight: 700;
outline: none;
border: none;
cursor: pointer;
text-decoration: none;

&:hover {
    transition: all 0.3s ease-in-out;
    background: var(--main-color-light);
    color: var(--main-color-dark);
}
`;