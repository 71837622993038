import React, {useState} from 'react';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne} from '../components/Functions/Data';
import Navbar from '../components/Navbar';
import MobileNavbar from '../components/MobileNavbar';
import Benefits from '../components/Benefits';
import TestimonialsSection from '../components/Testimonials';
import ContactSection from '../components/Contact';
import FooterSection from '../components/Footer';
// import CookieConsent from "react-cookie-consent";
// import {CookieHeader, CookieText, CookieLink, CookieCheckBox, CookieCheckText} from '../components/Cookie/CookieElements'

const Home = () => {

    const [isOpen,setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    

    return(
        
        <> 
            {/* <CookieConsent
                overlay={'true'}
                setDeclineCookies={'true'}
                buttonText={'Alle akzeptieren'}
                declineButtonText={'Speichern'}
                enableDeclineButton={'true'}
                flipButtons={'true'}
                style={{ height: 'auto',display:'flex', flexDirection:'row', justifyContent: 'center', alignItems: 'center', padding: '25px'}}
                buttonStyle={{display:'flex', flexDirection:'row', padding: '16px', background: '#C70000', color: '#E2E2E2'}}
                declineButtonStyle={{display:'flex', flexDirection:'row', justifyContent: 'center', alignItems: 'center', padding: '16px', background: '#515151', color: '#C5C5C5'}}
            >
                <CookieHeader>Cookie Hinweis</CookieHeader>
                    <CookieText>
                        Wir nutzen Cookies auf unserer Webseite. Einige von Ihnen sind essenziell, während andere uns helfen, diese Webseite und Ihre Erfahrung zu verbessern. Zur <CookieLink to='/data'>Datenschutzerklärung </CookieLink>
                </CookieText>
                <CookieCheckBox
                    type={'checkbox'}
                    label={"Essentiell"}
                    checked
                    disabled>

                </CookieCheckBox>
                <CookieCheckText>Notwendig</CookieCheckText>
                <CookieCheckBox
                    type={'checkbox'}
                    >

                </CookieCheckBox>
                <CookieCheckText>Statistiken</CookieCheckText>
            </CookieConsent> */}
            <MobileNavbar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroSection />
            <InfoSection {...homeObjOne} />
            <Benefits />
            <TestimonialsSection />
            <ContactSection />
            <FooterSection />
        </>
    )
};

export default Home;