import styled from "styled-components";

export const BenefitsContainer = styled.section`
    background: var(--secondary-color-light);
    padding: 35px 25px 25px 25px;

    /* @media screen and (max-width: 768px){
    padding: 80px 0 80px 0;
    } */
`;

export const BenefitsWrapper = styled.div`
width: 100%;
/* padding: 48px ; */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
max-width: 1100px;
margin: 0 auto;
`;

export const BenefitsH1 = styled.h1`
font-size: 2.5rem;
color: var(--main-color-dark);
padding: 25px;
margin-bottom: 25px;
line-height: 1.1;
font-weight: 600;

:before {
  position: absolute;
  bottom: 0;
  width: 60px;
  height: 3px;
  content: "";
  background-color: var(--main-color-red);
  left:50%; 
  margin-left:-30px;
}

@media screen and (max-width: 480px){
    font-size: 2rem;
}
`;

export const BenefitsCard = styled.div`
display: grid;
grid-template-columns: repeat(4, 1fr);
grid-template-rows: 1fr;
grid-auto-rows: 1fr;
grid-column-gap: 15px;
grid-row-gap: 15px; 
@media screen and (max-width: 1100px){
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
}
@media screen and (max-width: 670px){
    grid-template-columns: 1fr;
grid-template-rows: repeat(4, 1fr);
}
`;

export const IconBox = styled.div`
height: 100%;
padding: 10px;
background: var(--main-color-light);
/* background: rgba( 255, 255, 255, 0.55 ); */
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
backdrop-filter: blur( 10px );
-webkit-backdrop-filter: blur( 10px );
border: 1px solid rgba( 255, 255, 255, 0.18 );
text-align: center;

&:hover{
    transition: all 0.2s ease-in-out;
    border-color: var(--main-color-red);
    transform: scale(1.02);
    cursor: pointer;
}
`;

export const Icon = styled.div`
margin: 0 auto 20px auto;
padding-top: 17px;
display: inline-block;
text-align: center;
font-size: 36px;
line-height: 1;
color: var(--main-color-red);
`;

export const BenefitsH3 = styled.h2`
font-weight: bold;
font-size: 1em;
color: var(--main-color-dark);
&:hover{
    border-color: var(--main-color-red);
}   
`;

export const BenefitsP = styled.p`
font-size: 0.9em;
font-weight: 300;
`;
