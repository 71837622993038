import React from 'react';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, Heading, Subtitle, BtnWrap, InfoBtnLink, ImgWrap, Img } from './InfoSectionElements';

const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description1, description2, buttonLabel, img, alt, primary, dark, dark2}) => {
  return (
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <AnimationOnScroll animateIn="animate__fadeIn">
                <Heading lightText={lightText}>{headline}</Heading>
                </AnimationOnScroll>
                <AnimationOnScroll delay={100} animateIn="animate__fadeInLeft">
                <Subtitle darkText={darkText}>{description1}</Subtitle>
                <Subtitle darkText={darkText}>{description2}</Subtitle>
                </AnimationOnScroll>
                <BtnWrap>
                <AnimationOnScroll delay={200} animateIn="animate__fadeInUp">
                  <InfoBtnLink to="contact"
                  smooth={true} duration={300} spy={true} exact='true' offset={-80}>
                    {buttonLabel} 
                  </InfoBtnLink>
                  </AnimationOnScroll>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
              <AnimationOnScroll delay={400} animateIn="animate__fadeInDown">
                  <Img src={img} alt={alt}/>
              </AnimationOnScroll>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
  )
}

export default InfoSection;
