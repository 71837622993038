import React from 'react';
import ImprintSection from '../components/Imprint';
import ScrollToTop from '../components/Functions/ScrollToTop';
import FooterSection from '../components/Footer';

const ImprintPage = () => {

  return (
    <>
      <ScrollToTop />
      <ImprintSection />
      <FooterSection />
    </>
  );
};

export default ImprintPage;