import React from 'react';
import FooterSection from '../components/Footer';
import ScrollToTop from '../components/Functions/ScrollToTop';
import ResumeSection from '../components/Resume';
import { resumeObj } from '../components/Functions/Data';

const ResumePage = () => {
  return (
    <>
      <ScrollToTop />
      <ResumeSection {...resumeObj} />
      <FooterSection />
    </>
  );
};

export default ResumePage;