import React from "react";
import logo from '../../images/logo/Logo_Performance_WText.png';
import logo2 from '../../images/l-e-font-2.png';
import {animateScroll as scroll} from 'react-scroll';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BsInstagram, BsWhatsapp, BsGoogle} from 'react-icons/bs';
import { Footer, Links, LegalLinks, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink  } from "./FooterElements";

const FooterSection = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return(
        <Footer id="footer">
            <Container>
            <SocialLogo to="/" onClick={toggleHome}>
                    <img src={logo} width={170} alt="LE Coding"></img>
                    </SocialLogo>
                <Links>
                    <LegalLinks to="/data">Datenschutzerklärung</LegalLinks> | <LegalLinks to="/imprint">Impressum</LegalLinks>
                </Links>
                <SocialIcons>
                        {/* <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                        <BsFacebook />
                        </SocialIconLink> */}
                        <SocialIconLink href="https://www.instagram.com/edward_kankam/" target="_blank" aria-label="Instagram">
                        <BsInstagram />
                        </SocialIconLink>
                        <SocialIconLink href="https://wa.me/+491629080557" target="_blank" aria-label="Whatsapp">
                        <BsWhatsapp />
                        </SocialIconLink>
                        <SocialIconLink href="https://www.google.com/search?q=edward+kankam/" target="_blank" aria-label="Google">
                        <BsGoogle/>
                        </SocialIconLink>
                    </SocialIcons>
                    <SocialMediaWrap>
                    <SocialLogo to="/" onClick={toggleHome}>
                    <img src={logo2} width={170} alt="LE Coding"></img>
                    </SocialLogo>
                    <WebsiteRights>Edward Kankam © {new Date().getFullYear()} All Rights reserved.</WebsiteRights>
                    </SocialMediaWrap>
            </Container>
        </Footer>
    )
}

export default FooterSection;