import styled from 'styled-components';
import '../../App.css';
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';

export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? 'var(--main-color-light)' : 'transparent')};
    box-shadow: ${({scrollNav}) => (scrollNav ? 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px' : 'transparent')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 99;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
    `;

    export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
    `;

    export const NavLogo = styled(LinkR)`
    justify-self: flex-start;
    cursor: pointer;
    display: flex;
    align-items: center;
    `;

    export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px){

        justify-self: flex-end;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        color: ${({scrollNav}) => (scrollNav ? 'var(--main-color-light)' : 'var(--main-color-dark)')};
    }
    `;

    export const NavMenu = styled.ul`
    margin-top: 16px;
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
    `;

    export const NavItem = styled.li`
    height: 80px;
    `;

    export const NavLinks = styled(LinkS)`
    color: ${({scrollNav}) => (scrollNav ? 'var(--main-color-dark)' : 'var(--main-color-light)')};
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    font-weight: 300;
    cursor: pointer;

    &:hover {
        transition: all 0.4s ease-in;
        color: var(--main-color-red);
    }

    &.active {
        transition: all 0.2s ease-in;
        color: var(--main-color-red);
    }
    `;

    export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px){
        display: none;
    }
    `;

    export const NavExtLink = styled(LinkR)`
    color: ${({scrollNav}) => (scrollNav ? 'var(--main-color-dark)' : 'var(--main-color-light)')};
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    font-weight: 300;
    cursor: pointer;

    &:hover {
        transition: all 0.4s ease-in;
        color: var(--main-color-red);
    }

    &.active {
        transition: all 0.2s ease-in;
        color: var(--main-color-red);
    }
    `;

    export const NavBtnLink = styled(LinkR)`
    background: transparent;
    white-space: nowrap;
    padding: 10px 22px;
    color: ${({scrollNav}) => (scrollNav ? 'var(--main-color-dark)' : 'var(--main-color-light)')};
    border: 2.5px solid var(--main-color-red);
    font-size: 16px;
    font-weight: 300;
    outline: none;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        transition: all 0.3s ease-in-out;
        background: var(--main-color-red);
        color: var(--main-color-light);
    }
    `;