import styled from "styled-components";
import {Link} from 'react-router-dom';
import { ExternalLink } from "react-external-link";

export const DataProtection = styled.section`
left: 0;
top: 0;
right: 0;
bottom: 0;
background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(226,226,226,0.8759103983390231) 52%);
`;

export const Icon = styled(Link)`
display: flex;
padding: 15px;
justify-self: flex-start;
cursor: pointer;
align-items: center;
`;

export const DataH1 = styled.h1`
text-align: center;
font-size: 2.5rem;
color: var(--main-color-dark);
padding: 25px;
margin-bottom: 24px;
line-height: 1.1;
font-weight: 600;

:before {
  position: absolute;
  bottom: 0;
  width: 60px;
  height: 3px;
  content: "";
  background-color: var(--main-color-red);
  left:50%; 
  margin-left:-30px;
}

@media screen and (max-width: 500px){
    font-size: 1.6rem;
}
`;

export const DataDiv = styled.div`

`;

export const DataP = styled.p`
color: var(--main-color-dark);
`;

export const DataStrong = styled.strong`

`;

export const DataBr = styled.br`

`;

export const DataLink = styled(ExternalLink)`

`;