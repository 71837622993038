import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import "animate.css/animate.min.css";
import {FaChevronLeft} from 'react-icons/fa'; 
import { AnimationOnScroll } from 'react-animation-on-scroll';
import logo from '../../images/logo/Logo_Performance_WText.png';
import { Resume, ResumeH1, ResumeH3, ResumeH4, ResumeP, Icon, NavBtn, NavBtnLink  } from "./ResumeElements";

const ResumeSection = ({text1}) => {

    return(
        <Resume id="resume">
            <NavBtn>
                <Icon to="/" > <img src={logo} width={90} height='auto' alt="Performance Room Logo"></img></Icon>
                <NavBtnLink to="/" smooth={true} duration={500} spy={true} exact='true' offset={-80}><FaChevronLeft/> Zurück</NavBtnLink> 
            </NavBtn>
            <Container>
                <ResumeH1>Mein Resume</ResumeH1>
                <Row>
                    <Col lg="12">
                        <ResumeH3>Über Mich</ResumeH3>
                        <AnimationOnScroll initiallyVisible={true} animateIn="animate__fadeIn">
                            <ResumeP>Hi, ich bin Edward, geboren und aufgewachsen im schönen Stuttgart. Schon immer ist Sport meine Leidenschaft.</ResumeP>
                            <ResumeP>Mit Fußball hat die Liebe und meine Faszination für Sport angefangen, nach ein paar Jahren ist mir jedoch bewusst geworden, dass Fußball mich nicht so sehr fordert, wie American Football das kann. Hier habe ich in der 1. und 2. Bundesliga gespielt. Danach hat es mich zum Kampfsport gezogen
                            (Brazilian Jiu- Jutsu & Thaiboxen). Das Krafttraining hat mich in all dieser Zeit bis heute begleitet. Ich war immer auf der Suche das eigene Krafttraining zu optimieren, mit dem Willen meine Performance zu Steigerung und so Sport auf einem hohen Leistungsniveau auszuüben.
                            </ResumeP>
                            <ResumeP>Im Teamsport lernte ich früh, wie wichtig es ist, im Team zusammen zu arbeiten, mit der Unterstützung und Motivation in einem Team erreicht man seine gesteckten Ziele mit Leichtigkeit.</ResumeP>
                            <ResumeP>Aus diesem Grund habe ich mich entschlossen aus meiner Leidenschaft, dem Sport, auch meinen Beruf zu machen. Meine Ausbildung zum Sport-/ Gesundheitstrainer und Fitnesskaufmann habe ich in München an der IST gemacht. Doch dabei habe ich es nicht belassen - ich habe ein knappes Jahr in Hongkong gelebt und war dort als Studioleiter unter anderem für die Einarbeitung der Mitarbeiter in drei Fitnessstudios verantwortlich. Während der Zeit in Hongkong habe ich auch als Personal Trainer für verschiede Kunden in verschieden Bereichen gearbeitet.</ResumeP>
                            <ResumeP>Zurück in der Heimat habe ich verschiedene Seminare im Bereich Training und Ernährung in Deutschland und den USA besucht. Vor ein paar Jahren habe ich mich als Personal Trainer selbständig gemacht. Hier konnte ich schon mehreren Kunden dabei unterstützen ihre Ziele zu erreichen, wie Körperfett zu reduzieren, Muskeln aufzubauen und somit ihre Leistungsfähigkeit steigern. Auch mein Bootcamp hat sich etabliert und viele Kunden dazu animiert an der frischen Luft etwas für sich und ihr Wohlbefinden zu tun.</ResumeP>
                            <ResumeP>Mich zeichnet aus, dass ich den Anspruch an mich selbst habe, mich stets weiterzuentwickeln. Diese Leidenschaft möchte ich, sei es durch Training oder angepasste Ernährung, an meine Kund*innen weitergeben.</ResumeP>
                        </AnimationOnScroll>
                    </Col>
                    <Col lg="12">
                        <ResumeH3>Qualifikationen</ResumeH3>
                        <Row>
                            <Col lg="4">
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>IHK Sport- und Fitnesskaufmann </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>IST Fitnesstraining B- Lizenz </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>IST Personal Trainer B- Lizenz  </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>IST Sport- und Gesundheitstrainer Diplom  </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>IST Medizinisches Fitnesstraining A Lizenz  </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>IST Rückentraining A- Lizenz</ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>IST Sporternährung B-Lizenz </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>YPSI Ernährung, Hormone & Hautfaltenmessung Modul 4 </ResumeH4>
                                </AnimationOnScroll>
                            </Col>
                            <Col lg="4">
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>YPSI Sportspezifisches Training & Business Modul 6 </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>YPSI Programm Design Modul 3 </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>PTM Fat Loss Bootcamp </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>YPSI Bewegungsanalyse & Pre-/Rehabilitation Modul 5 </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>KILO Strength Society Online Program Desgin  </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>KILO Sports Specific Training Camp  </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>YPSI Mentorship Programm Individualisierte Ernährung Modul B </ResumeH4>
                                </AnimationOnScroll>
                            </Col>
                            <Col lg="4">
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>YPSI Webinar 20 Tricks für mehr Before 'n' After Resultate  </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>YPSI Mentorship Programm Biochemie für Personal Trainer  </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>Clean health Fitness Institute Advance Program Design </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>YPSI Funktionelle Anatomie und Übungsausführung Modul 2 </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>YPSI Functional Nutrition - Mikrobiom & Body Comp </ResumeH4>
                                </AnimationOnScroll>
                                <AnimationOnScroll delay={120} animateIn="animate__fadeIn">
                                    <ResumeH4>M10 Mentorship Personal Training Programm  </ResumeH4>
                                </AnimationOnScroll>
                            </Col>
                        </Row>
                    </Col> 
                </Row>
            </Container>
        </Resume>
    )
}

export default ResumeSection;