import React, { useState, useEffect } from 'react';
import './App.css';
import Home from './pages';
import DataPage from './pages/dataprotection';
import ImprintPage from './pages/imprint';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { HashRouter } from "react-router-dom";

import img from '../src/images/logo/Logo_Performance_WText.png';
import ServicePage from './pages/services';
import ResumePage from './pages/resume';

function App() {

  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
    {loading ? (
          <div className="loader-container">
            <div className="spinner">
              <img src={img} alt='loading Logo'></img>
              <h5>Wir bereiten noch kurz alles vor für Sie....</h5>
            </div>
          </div>
      ) : (
      <HashRouter>
        <Routes>
          <Route path='/' element={<Home />} exact />
          <Route path='/resume' element={<ResumePage />} />
          <Route path='/services' element={<ServicePage />}  />
          <Route path='/data' element={<DataPage />}  />
          <Route path='/imprint' element={<ImprintPage />}  />
        </Routes>
      </HashRouter>
      )}
    </>
  );
}

export default App;
