import styled from "styled-components";
import img from '../../images/training-3-min.webp';
import { ExternalLink } from "react-external-link";
import {Link as LinkR} from 'react-router-dom';
import { Link as LinkS } from "react-scroll";

export const Footer = styled.section`
background: radial-gradient(circle, rgba(0,0,0,0.8450980734090512) 38%, rgba(48,48,48,0.937535048198967) 100%), url(${img}) center no-repeat;
background-size: cover;
color: var(--main-color-light);
font-size: 14px;
text-align: center;
padding: 80px 0;
position: relative;
`;

// export const FooterH1 = styled.h1`
// text-align: center;
// font-size: 2.5rem;
// color: var(--secondary-color-light);
// padding: 50px;
// margin-bottom: 24px;
// line-height: 1.1;
// font-weight: 600;

// @media screen and (max-width: 480px){
//     font-size: 2rem;
// }
// `;

export const Links = styled.div`
margin: 3vh 0 3vh 0;
`;

export const LegalLinks = styled(LinkR)`
margin: 25px;
color: var(--secondary-color-light);

&:hover {
color: var(--main-color-red);
transition: all 0.3s ease-in-out; 
cursor: pointer;
}
`;

export const SocialMediaWrap = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
max-width: 700px;
margin: 40px auto 0 auto;

/* @media screen and (max-width: 820px){
    flex-direction: column;
} */
`;

export const SocialLogo = styled(LinkS)`
height: auto;
cursor: pointer;
`;

export const WebsiteRights= styled.small`
margin-top: 40px;
/* @media screen and (max-width: 700px){
 margin-left: 0;   
} */
`;

export const SocialIcons = styled.div`
 text-align: center;
 display: flex;
justify-content: center;
align-items: center;
gap: 25px;
`;

export const SocialIconLink = styled(ExternalLink)`
color: #daf0f3;
font-size: 24px;

&:hover{
    color: var(--main-color-red);
    cursor: pointer;
    transition: 0.3s ease-out;
}
`;