import React from 'react';
import FooterSection from '../components/Footer';
import ScrollToTop from '../components/Functions/ScrollToTop';
import ServiceSection from '../components/ServiceOffer';

const ServicePage = () => {
  return (
    <>
      <ScrollToTop />
      <ServiceSection />
      <FooterSection />
    </>
  );
};

export default ServicePage;