import styled from "styled-components";
import {Link} from 'react-router-dom';

export const Resume = styled.section`
left: 0;
top: 0;
right: 0;
bottom: 0;
padding: 15px;
background: #ffffff;
`;

export const Icon = styled(Link)`
display: flex;
padding: 15px;
justify-self: flex-start;
cursor: pointer;
align-items: center;
`;

export const ResumeH1 = styled.h1`
text-align: center;
font-size: 2.5rem;
color: var(--main-color-dark);
padding: 25px;
margin-bottom: 24px;
line-height: 1.1;
font-weight: 600;

:before {
  position: absolute;
  bottom: 0;
  width: 60px;
  height: 3px;
  content: "";
  background-color: var(--main-color-red);
  left:50%; 
  margin-left:-30px;
}

@media screen and (max-width: 480px){
    font-size: 2rem;
}
`;

export const ResumeH3 = styled.h3`
text-align: center;
font-size: 26px;
font-weight: 700;
margin-top: 20px;
margin-bottom: 40px;
color: var(--secondary-color-dark);
`;

export const ResumeH4 = styled.h4`
text-align: center;
line-height: 12px;
font-size: 13px;
font-weight: 700;
text-transform: uppercase;
color: var(--main-color-dark);
padding: 10px;
`;

export const ResumeP = styled.p`
color: var(--secondary-color-dark);
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    `;

    export const NavBtnLink = styled(Link)`
    background: transparent;
    white-space: nowrap;
    padding: 10px 22px;
    color: var(--main-color-dark);
    border: 2.5px solid var(--main-color-red);
    font-size: 16px;
    font-weight: 300;
    outline: none;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        transition: all 0.3s ease-in-out;
        background: var(--main-color-red);
        color: var(--main-color-light);
    }
    `;