import React, {useEffect, useState} from 'react';
import {FaBars} from 'react-icons/fa';
import {animateScroll as scroll} from 'react-scroll';
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, NavExtLink
} from './NavbarElements';
import logo from '../../images/logo/Logo_Performance_WText.png';

const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 180){
            setScrollNav(true);
        }else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

  return (
    <>
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogo to='/' onClick={toggleHome}>
                    <img src={logo} width={70} height='auto' alt="Performance Room Logo"></img>
                </NavLogo>
                <MobileIcon  onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                       <NavLinks scrollNav={scrollNav} to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Home</NavLinks> 
                    </NavItem>
                    <NavItem>
                       <NavLinks scrollNav={scrollNav} to="benefits" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Service</NavLinks> 
                    </NavItem>
                    <NavItem>
                       <NavExtLink scrollNav={scrollNav} to="/resume" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Über mich</NavExtLink> 
                    </NavItem>
                    <NavItem>
                       <NavLinks scrollNav={scrollNav} to="contact" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Kontakt</NavLinks> 
                    </NavItem>
                </NavMenu>
                <NavBtn>
                   <NavBtnLink scrollNav={scrollNav} to="/services" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Angebote</NavBtnLink> 
                </NavBtn>
            </NavbarContainer>
        </Nav>
    </>
  );
}

export default Navbar;

