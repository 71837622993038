import styled, {keyframes} from "styled-components";
import '../../App.css';
import {FaAngleDoubleDown} from 'react-icons/fa'; 
import { Link } from "react-scroll";
import { fadeIn } from 'react-animations';

export const HeroContainer = styled.div`
background: var(--main-color-dark);
display: flex;
justify-content: center;
align-items: center;
padding: 0 30px;
height: 800px;
position: relative;
z-index: 1;

:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(0,0,0,0.77) 0%, rgba(0,0,0,0.89860627844888) 100%);
    z-index: 2;
}
`;

 export const HeroBg = styled.div`
 position: absolute;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0%;
 width: 100%;
 height: 100%;
 overflow: hidden;
 `; 

export const ImgBg = styled.img`
width: 100%;
height: 100%;
--o-object-fit: cover;
object-fit: cover;
background: var(--main-color-dark);
`;

export const HeroContent = styled.div`
z-index: 3;
max-width: 1200px;
position: absolute;
padding: 8px 24px;
display: flex;
flex-direction: column;
align-items: center;
`;

export const HeroImg = styled.img`
width: 300px;  
height: auto; 
max-height: 230px;
@media screen and (max-width: 768px){
    width: 180px;
}
@media screen and (max-width: 480px){
    width: 150px;
}
`;

export const HeroP = styled.p`
margin-top: 24px;
color: var(--main-color-light);
font-size: 24px;
text-align: center;
max-width: 600px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
 font-size: 13px;   
}
`;

export const HeroBtnWrapper = styled.div`
margin-top: 32px;
display: flex;
flex-direction: column;
align-items: center;
`;

export const BeginIconArrow = styled(Link)`
cursor: pointer;
margin-top: -20px;
display: flex;
justify-content: center;
align-items: center;
`;

export const ArrowDown= styled(FaAngleDoubleDown)`
font-size: 45px;
color: var(--main-color-light);
`;

export const fadeInAnimation = keyframes`${fadeIn}`;
 
export const FadeInDiv = styled.div`
  animation: 2s ${fadeInAnimation};
`;