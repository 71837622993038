import React from "react";
import {BsInstagram, BsWhatsapp, BsGoogle, BsPhone, BsEnvelope} from 'react-icons/bs';
// import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import { Contact, InfoBox, ContactH3, ContactP, SocialIcons, SocialLink, ContactH1, ContactIcon} from "./ContactElements";

const ContactSection = () => {

    return(
    <Contact id="contact">
        <Container>
        <ContactH1>Get In Touch</ContactH1>
        <Row>
            <Col lg="12" py="3">
                <Row>
                    <Col md="12">
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                        <InfoBox>
                            {/* <ContactIcon><BsFillShareFill /></ContactIcon>
                            <ContactH3>Soziale Medien</ContactH3> */}
                            <ContactH3>Edward Kankam</ContactH3>
                            <SocialLink href="tel:+491629080557"><ContactIcon><BsPhone /></ContactIcon><ContactP> +49 162 9080557</ContactP></SocialLink>
                            <SocialLink href="mailto:training@pr.ek.de"><ContactIcon><BsEnvelope /></ContactIcon><ContactP> training@pr-ek.de</ContactP></SocialLink>
                            <SocialIcons>
                                {/* <SocialLink href="https://www.facebook.com/edward.kankam.9047"><BsFacebook /></SocialLink> */}
                                <SocialLink href="https://www.instagram.com/edward_kankam/"><BsInstagram /></SocialLink>
                                <SocialLink href="https://wa.me/+491629080557"><BsWhatsapp /></SocialLink>
                                <SocialLink href="https://www.google.com/search?client=firefox-b-d&q=performance+room+edward+kankam"><BsGoogle /></SocialLink>
                            </SocialIcons>
                        </InfoBox>
                    </AnimationOnScroll>
                    </Col>
                    {/* <Col md="12">
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                        <InfoBox>
                        <iframe title="Adress Performance Room Edward Kankam" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2628.914476710917!2d9.161463933848625!3d48.78352297163409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799db3dc34fd757%3A0x6e16845ab13878d4!2sKornbergstra%C3%9Fe%2026%2C%2070176%20Stuttgart!5e0!3m2!1sde!2sde!4v1610382354957!5m2!1sde!2sde" 
                        width="100%" height="400" frameborder="0" style={{border:"0", padding: "20px"}} allowfullscreen="true" aria-hidden="false" tabindex="0"></iframe>
                        </InfoBox>
                    </AnimationOnScroll>
                    </Col> */}
                    {/* <Col md="6">
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                        <InfoBox>
                        <form method="post" action="email.php" >
                            <label>
                                Name
                                <br/>
                                <input type="text" id="Name" name="Name" required/>
                            </label>
                            <label  for="Email" >
                                E-Mail-Adresse
                                <br/>
                                <input type="email" id="Email" name="Email" required/>
                            </label>
                            <br/>
                            <label for="Nachricht">
                                Ihre Nachricht
                                <br/>
                                <textarea style id="Nachricht" name="Nachricht"  rows="20" required></textarea>
                            </label>
                            <div>
                                <label>
                                <input type="checkbox" name="check" required/>
                                Ich bestätige, dass ich mit der 
                                    Verarbeitung meiner Eingaben zum Zwecke
                                    der Anfragebearbeitung, einverstanden bin.
                                </label>
                            </div>
                            <input type="submit" name="submit" value="Absenden"/>
                        </form>
                        </InfoBox>
                        </AnimationOnScroll>
                    </Col> */}
                    {/* <Col md="6">
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                        <InfoBox>
                        <ContactIcon><BsEnvelope /></ContactIcon>
                            <ContactH3>Schick mir eine Email</ContactH3>
                            <SocialLink href="mailto:training@pr.ek.de"><ContactP>training@pr-ek.de</ContactP></SocialLink>
                        </InfoBox>
                        </AnimationOnScroll>
                    </Col>
                    <Col md="6">
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                        <InfoBox>
                        <ContactIcon><BsPhone /></ContactIcon>
                            <ContactH3>Erreiche mich telefonisch</ContactH3>
                            <SocialLink href="tel:+491629080557"><ContactP>+49 162 9080557</ContactP></SocialLink>
                        </InfoBox>
                        </AnimationOnScroll>
                    </Col> */}
                </Row>
            </Col>
        </Row>
        </Container>
    </Contact>
    )
}

export default ContactSection;