import React from 'react';
import DataProtectionSection from '../components/DataProtection';
import FooterSection from '../components/Footer';
import ScrollToTop from '../components/Functions/ScrollToTop';

const DataPage = () => {
  return (
    <>
      <ScrollToTop />
      <DataProtectionSection />
      <FooterSection />
    </>
  );
};

export default DataPage;