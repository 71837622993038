import styled from "styled-components";
import {Link} from 'react-scroll';

export const InfoContainer = styled.div`
background: var(--main-color-light);

@media screen and (max-width: 768px){
    padding: 100px 0 200px 0;
}
`;

export const InfoWrapper = styled.div`
display: grid;
z-index: 1;
height: 700px;
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;

padding: 0 24px;
justify-content: center;
`;

export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minimax(auto, 1fr);
align-items: center;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

@media screen and (max-width: 768px){
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};   
}
`;

export const Column1 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;
`;

export const Column2 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;
`;

export const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0;
padding-bottom: 60px;
`;

export const TopLine = styled.p`
color: var(--main-color-red);
font-size: 20px;
line-height: 16px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 16px;
`;

export const Heading = styled.h1`
font-size: 2rem;
max-width: 440px;
margin-bottom: 20px;
line-height: 1.1;
font-weight: 600;
color: var(--main-color-dark);

@media screen and (max-width: 480px){
 font-size: 32px;   
}
`;

export const Subtitle = styled.p`
max-width: 440px;
margin-bottom: 15px;
font-size: 1rem;
font-weight: 300;
line-height: 24px;
color: var(--secondary-color-dark);
`;

export const BtnWrap = styled.div`
margin-top: 25px;
display: flex;
justify-content: flex-start;
`;

export const ImgWrap = styled.div`
max-width: 555px;
`;

export const Img = styled.img`
width: 100%;
margin: 0 0 10px 0;
padding-right: 0; 
`;

export const InfoBtnLink = styled(Link)`
background: transparent;
white-space: nowrap;
padding: 10px 22px;
color: var(--main-color-dark);
border: 2.5px solid var(--main-color-red);
font-size: 16px;
font-weight: 700;
outline: none;
cursor: pointer;
text-decoration: none;

&:hover {
    transition: all 0.3s ease-in-out;
    background: var(--main-color-red);
    color: var(--main-color-light);
}
`;